/**
 * Awana website
 * main.js
 * Main script file
 * @Author: Kubo HQ (Fabio Pinto da Costa)
 * @Date: 2017-10-06T12:24
 * @Email: developers@kubokhq.com
 */
(() => {
    var NAV_HEIGHT = 120;
    var WINDOW_WIDTH = $(window).width();
    // On clicking the legsContent selectable elements,
    // Make them active
    $("#legsContent .selectable").each((index, element) => {
        let selectButton = $(element).find(".select");
        let diff = 0;
        if ($(element).attr("id").match(/designedForYou/)) {
            diff = 200;
        }
        selectButton.on("click touchend", (e) => {
            e.preventDefault();
            // Activating the element
            if (!$(element).hasClass("active")) {
                if ($(window).innerWidth() > 1023) {
                    // Scroll the page to the top of the element
                    $("html, body").animate({
                        scrollTop: $(element).offset().top - NAV_HEIGHT - diff
                    }, 250);
                }
                // Hide the navbar
                $("nav").css({zIndex: -2});
                $("#worldMap *").css({zIndex: -11});
                // Show the overlay
                $("#legsContent").addClass("legsSection-selected");
                // Freeze body
                $("body").addClass("no-scroll");
                // Set the element to active
                $(element).addClass("active");
            } else {
                // Set the element to inactive
                $(element).removeClass("active");
                // Hide the overlay
                $("#legsContent").removeClass("legsSection-selected");
                // Show the navbar
                $("nav").css({zIndex: ""});
                $("#worldMap *").css({zIndex: ""});
                // Unfreeze body
                $("body").removeClass("no-scroll");
            }
        });
    });

    $("#worldMap .mobile-map .selectable").each((index, element) => {
        let selectButton = $(element).find(".select");
        selectButton.on("click touchend", (e) => {
            e.preventDefault();
            if (!$(element).hasClass("active")) {
                $(element).addClass("active");
            } else {
                $(element).removeClass("active");
            }
        });
    });

    // Hover the arms when hovering the map markers
    const mapCountries = ["usa", "brazil", "uk", "india", "china"];
    mapCountries.forEach((value, index) => {
        $(`#${value}Marker`).on("mouseover", (e) => {
            $(`#${value}Arm`).addClass("active");
        }).on("mouseleave", (e) => {
            $(`#${value}Arm`).removeClass("active");
        });
    });

    // On hitting a map marker, hide the map and show an overlay
    $("#worldMap .marker").each((index, element) => {
        $(element).on("click touchend", (e) => {
            e.preventDefault();
            $("#worldMap .map").addClass("show-overlay");
            let region = $(element).attr("id");
            let regionIndex = region.lastIndexOf("Marker");
            $(`#${region.substring(0, regionIndex)}Overlay`).addClass("active");
            // Center the map vertically
            if ($("html, body").scrollTop() >= $("#worldMap .map").offset().top - NAV_HEIGHT) {
                $("html, body").animate({
                    scrollTop: $("#worldMap .map").offset().top - NAV_HEIGHT - 40
                }, 750);
            }
        });
    });
    $("#worldMap .overlay").each((index, element) => {
        $(element).find(".close").on("click touchend", (e) => {
            e.preventDefault();
            $(element).removeClass("active");
            $("#worldMap .map").removeClass("show-overlay");
        })
    });
    try {
        $(':required').one('blur keydown', function() {
            $(this).addClass('touched');
        });   
    } catch (error) {
        
    }

    // Bottom form
    $("#formSection form").on("submit", (e) => {
        e.preventDefault();
        let message = `Name: ${$("#nameInput").val()}%0D%0ACompany: ${$("#companyInput").val()}%0D%0AEmail: ${$("#emailInput").val()}%0D%0APhone: ${$("#phoneInput").val()}`;
        if ($("#quoteCheck").prop("checked")) {
            message = message + "%0D%0AUser is interested in quotes.";
        }
        if ($("#queryCheck").prop("checked")) {
            message = message + "%0D%0AUser is interested in queries.";
        }
        if ($("#updatesCheck").prop("checked")) {
            message = message + "%0D%0AUser is interested in updates.";
        }
        if ($("#careerCheck").prop("checked")) {
            message = message + "%0D%0AUser is interested in careers.";
        }
        if ($("#otherCheck").prop("checked")) {
            message = message + "%0D%0AUser is interested in other.";
        }
        if ($("#emailCheck").prop("checked")) {
            message = message + "%0D%0AUser consents to be contacted via email.";
        }
        if ($("#phoneCheck").prop("checked")) {
            message = message + "%0D%0AUser consents to be contacted via phone.";
        }
        if ($("#smsCheck").prop("checked")) {
            message = message + "%0D%0AUser consents to be contacted via SMS.";
        }
        window.location.href = `mailto:jonathan@awanagroup.com?subject=Awana Group Website Response&body=${message}`;
    });

    // Anchor links
    $("#navLogo, #footerLogo").on("click touchend", (e) => {
        e.preventDefault();
        $("html, body").animate({
            scrollTop: 0
        }, 750);
    });
    $("#navLinks a, #footerLinks a, #mobileMenu a").each((index, element) => {
        let source = $(element).attr("href");
        if (source.match(/#[A-Za-z]/)) {
            $(element).on("click touchend", (e) => {
                e.preventDefault();
                $("html, body").animate({
                    scrollTop: $(source).offset().top - NAV_HEIGHT
                }, 750);
                $("#mobileMenu, #mobileMenuButton").removeClass("active");
            });
        }
    });
    const actionLink = (index) => {
        $("#navLinks a, #mobileMenu a").removeClass("active");
        $($("#navLinks a").get(index)).addClass("active");
        $($("#mobileMenu a").get(index)).addClass("active");
    };
    $(document).on("scroll", (e) => {
        if ($("html, body").scrollTop() >= $("#mastHead").offset().top - NAV_HEIGHT) {
            actionLink(0);
        }
        if ($("html, body").scrollTop() >= $("#columnContent").offset().top - NAV_HEIGHT) {
            actionLink(1);
        }
        if ($("html, body").scrollTop() >= $("#legsContent").offset().top - NAV_HEIGHT) {
            actionLink(2);
        }
        if ($("html, body").scrollTop() >= $("#worldMap").offset().top - NAV_HEIGHT) {
            actionLink(3);
        }
        if ($("html, body").scrollTop() >= $("#formSection").offset().top - NAV_HEIGHT) {
            actionLink(4);
        }
    });
    const fixMapHeight = () => {
        let mapWidth = $("#worldMap").width();
        if (mapWidth < 900) {
            $("#worldMap .map").height(mapWidth*0.496666666667);
        } else {
            $("#worldMap .map").height(447);
        }
    };
    $(window).on("resize", (e) => {
        fixMapHeight();
        if (WINDOW_WIDTH < 1024) {
            if ($(window).width() >= 1024) {
                // Set the element to inactive
                $("#legsContent .selectable").removeClass("active");
                // Hide the overlay
                $("#legsContent").removeClass("legsSection-selected");
                // Show the navbar
                $("nav").css({zIndex: ""});
                $("#worldMap *").css({zIndex: ""});
                // Unfreeze body
                $("body").removeClass("no-scroll");
            }
        }
        WINDOW_WIDTH = $(window).width();
    });
    fixMapHeight();
    // Mobile menu behaviour
    $("#mobileMenuButton").on("click touchend", (e) => {
        e.preventDefault();
        if ($("#mobileMenu").hasClass("active")) {
            $("#mobileMenu, #mobileMenuButton").removeClass("active");
        } else {
            $("#mobileMenu, #mobileMenuButton").addClass("active");
        }
    });
    var consent = 0;
    // Activate button submit on consent
    $("#formSection .consent").on("click touchend", function(e) {
        if (consent < 3 && $(this).prop("checked")) {
            consent++;
            $("#formSection form input[type=submit]").addClass("active");
        }
        if (consent > 0 && !$(this).prop("checked")) {
            consent--;
            if (consent === 0) {
                $("#formSection form input[type=submit]").removeClass("active");
            }
        }
    });
    $("#formSection form input[type=submit]").on("click touchend", function(e) {
        if (!$(this).hasClass("active")) {
            e.preventDefault();
        }
    });
})($);